<template>
  <CommonLayout v-if="test.title">
    <template #default>
      <div class="cabinet">
        <div class="cabinet__inner">
          <div class="cabinet__head">
            <div class="cabinet__row">
              <div class="cabinet__col">
                <h1 class="title">Командные тестирования</h1>
              </div>
            </div>
            <div class="cabinet__row mt-30">
              <div class="cabinet__col">
                <div class="test-head">
                  <div class="test-head__row">
                    <div class="test-head__group">
                      <div class="test-head__group-name">Тема тестирования</div>
                      <div class="test-head__group-title">
                        {{ test.title }}
                      </div>
                      <div class="test-head__group-desc">
                        {{ test.description }}
                      </div>
                    </div>
                  </div>
                  <div class="test-head__row">
                    <div class="test-head__col">
                      <div class="test-head__title">
                        Статус тестирования<Tooltip
                          class="test-head__tooltip"
                          :msg="`<table>
                                <tr><td>В работе</td><td>Пройдите тест</td></tr>
                                <tr><td>На модерации</td><td>Тестирование завершилось, ждите результат.</td></tr>
                                <tr><td>Выполнен</td><td>Результат посчитан, вы справились. Поздравляем!</td></tr>
                                <tr><td>НЕ ВЫПОЛНЕН</td><td>Результат посчитан, команда не достигла цели.</td></tr>
                              </table>`"
                        />
                      </div>
                      <div class="test-head__subtitle">
                        {{ test.state | formatTeamTest }}
                      </div>
                    </div>
                    <div class="test-head__col">
                      <div class="test-head__text">
                        Дата начала: {{ test.startDate | formatDate2
                        }}<br />Дата окончания: {{ test.endDate | formatDate2 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cabinet__col">
                <div class="test-legend">
                  <div class="test-legend__inner">
                    <div class="test-legend__head">
                      <Tooltip
                        v-if="test.hint"
                        class="test-legend__tooltip"
                        msg="Награда команде зависит от общего % верных ответов и количества участников завершивших тест. Награда назначается по низшему показателю. "
                      />
                      <div class="test-legend__title">Цель команды</div>
                    </div>
                    <div class="test-legend__body">
                      <table>
                        <thead>
                          <tr>
                            <th>% участников, завершивших тест</th>
                            <th>Общий %<br />верных<br />ответов</th>
                            <th>Награды</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="green">
                              <div class="overflow">
                                {{ test.fiveTargetParticipationPercent }}%
                              </div>
                            </td>
                            <td class="green">
                              <div class="overflow">
                                {{ test.fiveTargetCorrectAnswersPercent }}%
                              </div>
                            </td>
                            <td>
                              <img
                                src="@/assets/img/pic2/coin.svg"
                                width="15"
                                height="15"
                                alt=""
                              />
                              {{ test.fiveReward | priceFilter }}
                            </td>
                          </tr>
                          <tr>
                            <td class="yellow">
                              <div class="overflow">
                                {{ test.fourTargetParticipationPercent }}%
                              </div>
                            </td>
                            <td class="yellow">
                              <div class="overflow">
                                {{ test.fourTargetCorrectAnswersPercent }}%
                              </div>
                            </td>
                            <td>
                              <img
                                src="@/assets/img/pic2/coin.svg"
                                width="15"
                                height="15"
                                alt=""
                              />
                              {{ test.fourReward | priceFilter }}
                            </td>
                          </tr>
                          <tr>
                            <td class="pink">
                              <div class="overflow">
                                {{ test.threeTargetParticipationPercent }}%
                              </div>
                            </td>
                            <td class="pink">
                              <div class="overflow">
                                {{ test.threeTargetCorrectAnswersPercent }}%
                              </div>
                            </td>
                            <td>
                              <img
                                src="@/assets/img/pic2/coin.svg"
                                width="15"
                                height="15"
                                alt=""
                              />
                              {{ test.threeReward | priceFilter }}
                            </td>
                          </tr>
                          <tr>
                            <td class="red">
                              <div class="overflow">
                                {{ test.twoTargetParticipationPercent }}%
                              </div>
                            </td>
                            <td class="red">
                              <div class="overflow">
                                {{ test.twoTargetCorrectAnswersPercent }}%
                              </div>
                            </td>
                            <td>
                              <img
                                src="@/assets/img/pic2/coin.svg"
                                width="15"
                                height="15"
                                alt=""
                              />
                              {{ test.twoReward | priceFilter }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="ownTest">
            <div v-if="ownTest.isCompleted" class="test-result">
              <div class="test-result__inner">
                <div class="test-result__title">
                  <strong
                    >Ваш результат:
                    <span :class="[calcColor(ownTest.correctAnswersPercent)]"
                      >{{ ownTest.correctAnswersPercent }}%</span
                    ></strong
                  >
                </div>
                <div class="test-result__button">
                  <button
                    class="button button-default"
                    type="button"
                    @click="$modal.show('teamTest', { test })"
                  >
                    Посмотреть тест
                  </button>
                </div>
              </div>
            </div>
            <!-- показываем только в статусе «в работе» -->
            <div v-else-if="test.state === 1" class="test-result">
              <div class="test-result__inner">
                <div class="test-result__title">Вы еще не прошли тест</div>
                <div class="test-result__button">
                  <button
                    class="button button-default"
                    type="button"
                    @click="$modal.show('teamTest', { test })"
                  >
                    Пройти тест
                  </button>
                </div>
              </div>
            </div>
          </template>
          <div class="cabinet__body">
            <div class="test">
              <div class="test__head">
                <div class="test__col">
                  <table class="test-table">
                    <thead>
                      <tr>
                        <th>Участник команды</th>
                        <th>Результат, %</th>
                        <th>Награда</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="test__col">
                  <table class="test-table">
                    <thead>
                      <tr>
                        <th>Участник команды</th>
                        <th>Результат, %</th>
                        <th>Награда</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div class="test__body">
                <div class="test__col">
                  <table class="test-table">
                    <tbody>
                      <TeamTestPlayerTableItem
                        v-for="(player, index) in playersEven"
                        :key="index"
                        :player="player"
                        :scores="scores"
                        :state="test.state"
                      />
                    </tbody>
                  </table>
                </div>
                <div class="test__col">
                  <table class="test-table">
                    <tbody>
                      <TeamTestPlayerTableItem
                        v-for="(player, index) in playersOdd"
                        :key="index"
                        :player="player"
                        :scores="scores"
                        :state="test.state"
                      />
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="test-foot">
                <div v-if="test.state <= 2" class="test-foot__title">
                  Промежуточный результат команды:
                </div>
                <div v-else class="test-foot__title">
                  Финальный результат команды:
                </div>
                <div class="test-foot__list">
                  <div class="test-foot__item">
                    <span>% участников</span>
                    <strong
                      :class="[
                        calcColorParticipation(test.teamParticipationPercent),
                      ]"
                      >{{
                        typeof test.teamParticipationPercent === "number"
                          ? test.teamParticipationPercent
                          : "-"
                      }}</strong
                    >
                  </div>
                  <div class="test-foot__item">
                    <span>% верных ответов</span>
                    <strong
                      :class="[calcColor(test.teamCorrectAnswersPercent)]"
                      >{{
                        typeof test.teamCorrectAnswersPercent === "number"
                          ? test.teamCorrectAnswersPercent
                          : "-"
                      }}</strong
                    >
                  </div>
                  <div class="test-foot__item">
                    <span>Награда</span>
                    <strong v-if="typeof test.teamReward === 'number'"
                      ><img
                        src="@/assets/img/pic2/coin.svg"
                        width="25"
                        height="25"
                        alt=""
                      />{{ test.teamReward }}</strong
                    >
                    <strong v-else>-</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modals>
      <div class="modals">
        <ModalTeamTest :result="ownTest" @update="fetchData" />
      </div>
    </template>
  </CommonLayout>
  <CabinetContentLayout
    v-else
    :has-button-back="true"
    :is-loading="loading"
    title="Командные тестирования"
  >
    <p>
      В разделе «Командное тестирование» вы будете получать задания в виде
      <br />
      тестов на знание чего-либо. Для получения награды за прохождение <br />
      командного тестирования нужно выполнить два условия:
    </p>
    <p>
      1. Набрать достаточный средний балл правильных ответов по команде. <br />
      2. Обеспечить прохождение теста необходимым количеством участников
    </p>
    <p>О начале командного тестирования вам сообщит Совет старейшин.</p>
  </CabinetContentLayout>
</template>

<script>
import { GetTeamTesting } from "@/api/TeamTesting";
import ModalTeamTest from "@/components/ModalTeamTest.vue";
import TeamTestPlayerTableItem from "@/components/TeamTestPlayerTableItem.vue";
import Tooltip from "@/components/Tooltip.vue";
import CabinetContentLayout from "@/layout/CabinetContentLayout.vue";
import CommonLayout from "@/layout/CommonLayout.vue";
import { getColor } from "@/utils/format";
import { mapGetters } from "vuex";

export default {
  components: {
    CommonLayout,
    Tooltip,
    CabinetContentLayout,
    ModalTeamTest,
    TeamTestPlayerTableItem,
  },
  computed: {
    ...mapGetters(["getProfile"]),
    playersOdd() {
      return this.test.players.filter((e, i) => i % 2);
    },
    playersEven() {
      return this.test.players.filter((e, i) => !(i % 2));
    },
    ownTest() {
      return this.test.players.find(
        (e) => e.fullName === this.getProfile.fullName
      );
    },
    scores() {
      return [
        this.test.fiveTargetCorrectAnswersPercent,
        this.test.fourTargetCorrectAnswersPercent,
        this.test.threeTargetCorrectAnswersPercent,
        this.test.twoTargetCorrectAnswersPercent,
      ];
    },
  },
  data() {
    return {
      loading: false,
      test: {},
      questions: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const { data } = await GetTeamTesting();

        this.test = data;
      } catch (error) {
        new Error(error);
      } finally {
        this.loading = false;
      }
    },
    calcColorParticipation(value) {
      const {
        fiveTargetParticipationPercent,
        fourTargetParticipationPercent,
        threeTargetParticipationPercent,
        twoTargetParticipationPercent,
      } = this.test;

      return getColor(value, [
        fiveTargetParticipationPercent,
        fourTargetParticipationPercent,
        threeTargetParticipationPercent,
        twoTargetParticipationPercent,
      ]);
    },
    calcColor(value) {
      const {
        fiveTargetCorrectAnswersPercent,
        fourTargetCorrectAnswersPercent,
        threeTargetCorrectAnswersPercent,
        twoTargetCorrectAnswersPercent,
      } = this.test;

      return getColor(value, [
        fiveTargetCorrectAnswersPercent,
        fourTargetCorrectAnswersPercent,
        threeTargetCorrectAnswersPercent,
        twoTargetCorrectAnswersPercent,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  width: 100%;
  margin-top: 30px;
  &__head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    // overflow: auto;
    // @include scrollbar;
    // height: calc(100vh - 550px);
    // min-height: 280px;
  }
}

.test-skeleton {
  width: 100%;
}
.test-legend {
  background-image: url(~@/assets/img/pics/legend-bg-2.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 500px;
  height: 286px;
  &__inner {
    padding: 23px 55px;
  }
  &__head {
    position: relative;
  }
  &__tooltip {
    position: absolute;
    top: 8px;
    right: 100%;
  }
  &__title {
    font-size: 24px;
    color: #50ffeb;
  }
  &__body {
    table {
      width: 100%;
      text-align: left;
      border-spacing: 0px;
      thead {
        tr {
          th {
            font-weight: normal;
            font-size: 18px;
            color: #ade5d9;
            vertical-align: bottom;
          }
        }
      }
      tbody {
        font-weight: 700;
        font-size: 19px;
        tr {
          td {
            white-space: nowrap;
            .overflow {
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-right: 5px;
            }
            img {
              display: inline-block;
              vertical-align: middle;
              margin-top: -2px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.test-table {
  text-align: left;
  width: 100%;
  border-spacing: 0;
  // border-spacing: 0 5px;
  ::v-deep {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: calc(100% - 240px);
        }
        &:nth-child(2) {
          width: 120px;
          text-align: center;
          white-space: nowrap;
          > div {
            justify-content: center;
          }
        }
        &:nth-child(3) {
          width: 120px;
          text-align: center;
          white-space: nowrap;
          > div {
            justify-content: center;
          }
        }
      }
    }
    thead {
      position: sticky;
      top: 0px;
      tr {
        th {
          font-weight: normal;
          font-size: 16px;
          // line-height: 14px;
          color: #ade5d9;
          &:first-child {
            padding-left: 35px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 18px;
          line-height: 22px;
          padding: 0;
          &:first-child {
            > div {
              padding-left: 35px;
            }
          }
          > div {
            background: rgba(0, 255, 255, 0.35);
            min-height: 50px;
            max-height: 65px;
            height: calc((100vh - 595px) / 7);
            padding: 10px 20px;
            margin: 3px 0;
            display: flex;
            align-items: center;
          }
          span {
            overflow: hidden;
            white-space: nowrap;
            max-width: 320px;
            display: block;
            text-overflow: ellipsis;
          }
          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            margin-bottom: -2px;
          }
        }
      }
    }
  }
}

.test-head {
  &__row {
    display: flex;
    align-items: flex-end;
  }
  &__col {
    margin-right: 50px;
  }
  &__tooltip {
    margin-bottom: -8px;
  }
  &__group {
    margin-bottom: 30px;
    padding-right: 30px;
    &-name {
      font-size: 18px;
      color: #ade5d9;
    }
    &-title {
      font-size: 40px;
      line-height: 44px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-desc {
      font-family: $fontRafale;
      font-size: 18px;
      margin-top: 20px;
    }
  }
  &__title {
    font-size: 18px;
    color: #ade5d9;
    display: flex;
    align-items: center;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #50ffeb;
    text-transform: uppercase;
  }
  &__text {
    font-size: 18px;
    color: #ade5d9;
    margin-bottom: 2px;
  }
}

.test-foot {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-size: 32px;
    line-height: 1;
    margin-top: -7px;
    &.big {
      font-size: 40px;
    }
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    margin-left: 40px;
    background: rgba(0, 255, 254, 0.15);
    height: 64px;
    width: 230px;
    text-align: center;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='64' viewBox='0 0 22 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2H2v60h20' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='64' viewBox='0 0 22 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2H2v60h20' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
      content: "";
    }
    span {
      display: block;
      font-size: 18px;
      color: #50ffeb;
    }
    strong {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 1;
      justify-content: center;
      img {
        margin-right: 8px;
        margin-bottom: -4px;
      }
    }
  }
}

.test-result {
  display: grid;
  place-items: center;
  width: 1233px;
  height: 200px;
  background-image: url(~@/assets/img/pics/test-bg.svg);
  position: relative;
  left: -3px;
  margin-top: 30px;
  &__inner {
    text-align: center;
    padding-bottom: 20px;
  }
  &__title {
    font-size: 32px;
    strong {
      color: #5effff;
    }
  }
  &__button {
    margin-top: 20px;
  }
}
</style>

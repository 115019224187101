<template>
  <tr v-if="player.isVacation">
    <td>
      <div>
        <span :title="player.fullName">{{ player.fullName }}</span>
      </div>
    </td>
    <td>
      <div><strong>-</strong></div>
    </td>
    <td>
      <div><strong>-</strong></div>
    </td>
  </tr>
  <tr v-else-if="player.isCompleted">
    <td>
      <div>
        <span :title="player.fullName">{{ player.fullName }}</span>
      </div>
    </td>
    <td>
      <div :class="[calcColor(player.correctAnswersPercent)]">
        <strong>{{ player.correctAnswersPercent }}</strong>
      </div>
    </td>
    <td>
      <div v-if="state <= 2"><strong>-</strong></div>
      <div v-else>
        <img src="@/assets/img/pic2/coin.svg" width="15" height="15" alt="" />{{
          player.playerReward
        }}
      </div>
    </td>
  </tr>
  <tr v-else>
    <td>
      <div>
        <span :title="player.fullName">{{ player.fullName }}</span>
      </div>
    </td>
    <td>
      <div></div>
    </td>
    <td>
      <div></div>
    </td>
  </tr>
</template>

<script>
import { getColor } from "@/utils/format";

export default {
  props: {
    player: {
      type: Object,
    },
    scores: {
      type: Array,
    },
    state: {
      type: Number,
    },
  },
  methods: {
    calcColor(value) {
      return getColor(value, this.scores);
    },
  },
};
</script>

<style lang="scss" scoped></style>

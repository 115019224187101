export const getColor = (
  value,
  scores,
  colors = ["green", "yellow", "pink", "red"]
) => {
  let result = colors[0];

  if (typeof value !== "number") {
    return "";
  }

  scores.forEach((e, i) => {
    if (value < e) {
      result = colors[i + 1];
    }
  });

  return result;
};

<template>
  <modal
    class="modal-overlay theme"
    :name="name"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <ModalLayout :name="name">
      <div class="modal-task">
        <div class="modal-task__name">Командные тестирования</div>
        <div class="modal-task__title">{{ test.title }}</div>
        <div class="modal-task__desc">
          {{ test.description }}
        </div>
        <div class="modal-task__caption">{{ data.length }} вопроса</div>
        <div v-if="isResult" class="modal-task__result">
          Ваш результат:
          <span :class="[calcColor(result.correctAnswersPercent)]"
            >{{ result.correctAnswersPercent }}%</span
          >
        </div>
        <div class="modal-task__body">
          <TestForm v-if="isResult" :questions="data">
            <button
              type="button"
              class="button button-default button_big modal-task__button"
              @click="$modal.hide(name)"
            >
              Закрыть
            </button>
          </TestForm>
          <TestForm
            v-else-if="data && data.length"
            :questions="data"
            :isSubmitting="submit"
            @submit="onSubmit"
          />
        </div>
      </div>
    </ModalLayout>
  </modal>
</template>

<script>
import { SaveAnswersTeamTesting, StartTeamTesting } from "@/api/TeamTesting";
import TestForm from "@/components/TestForm.vue";
import ModalLayout from "@/layout/ModalLayout.vue";
import { getColor } from "@/utils/format";

export default {
  props: {
    result: {
      type: Object,
      result: false,
    },
  },
  components: {
    ModalLayout,
    TestForm,
  },
  data() {
    return {
      name: "teamTest",
      test: {},
      submit: false,
      data: [],
      isCompleted: false,
    };
  },
  computed: {
    isResult() {
      return this.isCompleted || this.result?.isCompleted;
    },
  },
  methods: {
    beforeOpen(event) {
      if (event.params.test) {
        this.test = event.params.test;
        this.fetchData();
      } else {
        this.$modal.hide(this.name);
        this.$modal.show("message", {
          message: "Не передан test",
        });
      }
      setTimeout(() => {
        document.getElementsByClassName("modal-close")[0].focus();
      }, 1000);
    },
    beforeClose() {
      this.$emit("close");
      this.submit = false;
    },
    normalizeAnswers(data) {
      data.forEach((question) => {
        question.currentAnswers = [];
        question.answers = question.allAnswers;

        question.playerAnswers = question.playerAnswerIds?.map(
          (e) => question.answers[Number(e) - 1]
        );
        question.correctAnswers = question.correctAnswerIds?.map(
          (e) => question.answers[Number(e) - 1]
        );
      });
      return data;
    },
    async fetchData() {
      try {
        const resp = await StartTeamTesting({ teamTestingId: this.test.id });

        this.data = this.normalizeAnswers(resp.data);
      } catch (error) {
        this.$modal.hide(this.name);
        this.$modal.show("message", {
          message: error ? error.data : "Произошла ошибка. Попробуйте позже!",
        });
      }
    },
    onSubmit(answers) {
      const temp = [];

      this.submit = true;

      this.data.forEach((item, index) => {
        const question = {
          questionId: item.questionId,
          answers: answers[index],
        };

        temp.push(question);
      });

      SaveAnswersTeamTesting({ teamTestingId: this.test.id }, temp)
        .then(async () => {
          await this.fetchData();
          this.isCompleted = true;
          this.$emit("update");
        })
        .catch((err) => {
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        })
        .finally(() => {
          this.submit = false;
        });
    },
    calcColor(value) {
      const {
        fiveTargetCorrectAnswersPercent,
        fourTargetCorrectAnswersPercent,
        threeTargetCorrectAnswersPercent,
        twoTargetCorrectAnswersPercent,
      } = this.test;

      return getColor(value, [
        fiveTargetCorrectAnswersPercent,
        fourTargetCorrectAnswersPercent,
        threeTargetCorrectAnswersPercent,
        twoTargetCorrectAnswersPercent,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped></style>

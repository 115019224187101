import request from "@/utils/request";

export function GetTeamTesting(params) {
  return request({
    url: "/api/player/TeamTesting/GetTeamTesting",
    method: "get",
    params,
  });
}

export function StartTeamTesting(params) {
  return request({
    url: "/api/player/TeamTesting/StartTeamTesting",
    method: "get",
    params,
  });
}

export function SaveAnswersTeamTesting(params, data) {
  return request({
    url: "/api/player/TeamTesting/SaveAnswersTeamTesting",
    method: "post",
    params,
    data,
  });
}
